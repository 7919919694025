import { TagContext, Tagger, Tags } from "../../../lib/tagging-type";
import { isItemBlock, isFeedResult, isPublishRecord } from "../../../lib/guards";
import { articleTemplateTags } from "./article";
import { calendarArticleTemplateTags } from "./calendar-article";
import { coastArticleTemplateTags } from "./coast-article";
import { coastGuestTemplateTags } from "./coast-guest";
import { coastShowTemplateTags } from "./coast-show";

const TEMPLATE_TAGS = {
  content: articleTemplateTags,
  "content:calendar": calendarArticleTemplateTags,
  "content:coast-to-coast-article": coastArticleTemplateTags,
  "content:coast-to-coast-show": coastShowTemplateTags,
  "content:coast-to-coast-guest": coastGuestTemplateTags,
};

export const itemTags: Tagger = (state: Tags, context: TagContext) => {
  const { block } = context;
  if (block && isItemBlock(block) && isFeedResult(block.item?.result) && isPublishRecord(block.item?.result.record)) {
    const content = block.item?.result.record;
    if (content && TEMPLATE_TAGS[content.type]) {
      return TEMPLATE_TAGS[content.type](state, context) || state;
    }
  }
  return state;
};
