export const performance = (method: string, value: string[]) => {
  if (!window.performance) {
    return;
  }

  const singleMarks = ["mark", "clearMarks", "clearMeasures", "getEntriesByName"];
  if (singleMarks.includes(method)) {
    window.performance[method](value[0]);
  }

  if (method === "measure") {
    window.performance.measure(value[0], ...value.slice(1));
  }
};
