import * as io from "io-ts";
import {
  CatalogKindTypes,
  getLocale,
  getTag,
  initSdkOpts,
  MaybeCatalogResolver,
  removePrefix,
} from "@ihr-radioedit/inferno-core";
import { slugify } from "@ihr-radioedit/sdk-utils";
import { inject } from "mobx-react";
import type { Store } from "@inferno/renderer-shared-core";
import { Remote } from "../../components/remote/Remote.component";
import AmpCatalogLegacy from "../../content-blocks/AmpCatalogLegacy.component";
import CatalogLegacy from "../../content-blocks/CatalogLegacy.component";

const EDITORIAL_PLAYER_QUERY = { sc: "inferno", campid: "EditorialPlayerB", pname: "local" };

const ArtistTagLoader = inject("store")(
  ({
    artist,
    locale,
    sdkOpts,
    store,
    isAmp = false,
  }: {
    artist: string;
    locale: string;
    sdkOpts: initSdkOpts;
    store?: Store;
    isAmp?: boolean;
  }) => {
    if (!store) {
      return null;
    }

    return (
      <Remote
        loader={async () => {
          const result = await getTag(artist, locale, sdkOpts);
          if (result?.source) {
            return {
              id: result.source.id,
              kind: CatalogKindTypes.artist,
              country: locale,
              description: result.source.source_name,
            };
          }

          return null;
        }}
        cacheKey={`artist-tag-${slugify(removePrefix(/^artist\//)(artist))}`}
        showLoading={false}
      >
        {({ data }) => {
          if (data) {
            const query = {
              ...EDITORIAL_PLAYER_QUERY,
              campid: store.request.query?.campid ?? EDITORIAL_PLAYER_QUERY.campid,
            };
            const props = {
              id: data.id ?? "",
              kind: CatalogKindTypes[data.kind],
              country: "",
              description: "",
              device_link: "",
              related_id: "",
              img: "",
              name: "",
              web_link: "",
              query,
            };

            return isAmp ? <AmpCatalogLegacy {...props} /> : <CatalogLegacy {...props} />;
          }

          return null;
        }}
      </Remote>
    );
  },
);

const DefaultCategoryCatalogLoader = inject("store")(
  ({
    store,
    category,
    locale,
    isAmp = false,
  }: {
    store?: Store;
    category: string;
    locale: string;
    isAmp?: boolean;
  }) => {
    if (!store || !category || !locale) {
      return null;
    }

    const defaultCatalog = store
      .getDefaultCategoryCatalog()
      ?.categories?.filter(
        ({ category: checkCategory, locale: checkLocale }) =>
          checkCategory === category && removePrefix(/^locales\//)(checkLocale) === locale,
      )
      .shift();

    if (!defaultCatalog?.item) {
      return null;
    }
    const query = {
      ...EDITORIAL_PLAYER_QUERY,
      campid: store.request.query?.campid ?? EDITORIAL_PLAYER_QUERY.campid,
    };
    const props = {
      id: defaultCatalog?.item.id?.toString(),
      kind: CatalogKindTypes[defaultCatalog?.item.kind],
      country: "",
      description: "",
      device_link: "",
      related_id: "",
      img: "",
      name: "",
      web_link: "",
      query,
    };

    return isAmp ? <AmpCatalogLegacy {...props} /> : <CatalogLegacy {...props} />;
  },
);

type MaybeCatalog = io.TypeOf<typeof MaybeCatalogResolver>;
export const ContentPlayerWidgetLoader = inject("store")(
  ({
    store,
    catalog,
    category,
    artist,
    isPodcastEmbed,
    isAmp = false,
  }: {
    store?: Store;
    catalog?: MaybeCatalog | null;
    category?: string;
    artist?: string;
    isPodcastEmbed?: boolean;
    isAmp?: boolean;
  }) => {
    if (!store) {
      return null;
    }

    const { site, request, getSdkOpts } = store;
    const locale = getLocale(site);

    if (catalog?.id && catalog?.kind) {
      const query = { ...EDITORIAL_PLAYER_QUERY, campid: request.query?.campid ?? EDITORIAL_PLAYER_QUERY.campid };
      const props = {
        id: catalog.id?.toString(),
        kind: CatalogKindTypes[catalog.kind],
        country: "",
        description: "",
        device_link: "",
        related_id: "",
        img: "",
        name: "",
        web_link: "",
        query,
        isPodcastEmbed,
      };

      return isAmp ? <AmpCatalogLegacy {...props} /> : <CatalogLegacy {...props} />;
    }

    if (artist) {
      return <ArtistTagLoader artist={artist} locale={locale} sdkOpts={getSdkOpts()} isAmp={isAmp} />;
    }

    return <DefaultCategoryCatalogLoader category={category ?? ""} locale={locale} isAmp={isAmp} />;
  },
);

export default ContentPlayerWidgetLoader;
