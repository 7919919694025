import loadable from "@loadable/component";
import { toJS } from "mobx";
import { inject } from "mobx-react";

import { ILog } from "@ihr-radioedit/inferno-core";
import {
  isItemBlock,
  isFeedResult,
  isCalendarPublishRecord,
  isPublishRecord,
  isLeadsCard,
  CcpaStatus,
} from "@inferno/renderer-shared-core";

import { getContentVideoBlock } from "../../lib/content-video-block";
import { isAuthorRecord } from "../../lib/guards";
import { isHowToListenBar } from "../../services/Sites.utils";
import type { PageBlockInterface } from "../Block.component";

const CalendarContent = loadable(() => import("./CalendarContent.component"));
const AuthorContent = loadable(() => import("./AuthorContent.component"));
const Content = loadable(() => import("./Content.component"));
const HowToListenBar = loadable(() => import("./HowToListenBar.component"));

const log = ILog.logger("Item.component");

export const Item = inject("store")(({ block, store }: PageBlockInterface) => {
  if (!store) {
    return null;
  }

  if (!isItemBlock(block)) {
    log.error("No item on block", block);
    return null;
  }

  if (isFeedResult(block.item?.result)) {
    const content = block.item?.result?.record;

    if (isLeadsCard(content) && isHowToListenBar(block.tags)) {
      return <HowToListenBar block={block} content={content} />;
    }

    if (isCalendarPublishRecord(content)) {
      return <CalendarContent content={content} block={block} />;
    }

    if (isAuthorRecord(content)) {
      return <AuthorContent content={content} block={block} />;
    }

    if (isPublishRecord(content)) {
      const updatedContentBlocks = getContentVideoBlock({
        block,
        content,
        provider: store?.site?.contentVideoProvider,
        queryProps: store?.request.query,
        privacyOptOut: store.getCcpaStatus() === CcpaStatus.OptOut,
      });
      return <Content content={content} block={block} updatedContentBlocks={updatedContentBlocks} />;
    }

    if (content) {
      // If we don't know what it is, and it's not falsy, log.
      log.error("Unknown item block", toJS(block.item));
    }
  }
  return null;
});

export default Item;
