import type { Location } from "history";
import { observe } from "mobx";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { triggerPixelPageView } from "../app/core/lib/integrations/facebook";
import { getMetadata } from "@inferno/renderer-shared-core";
import type { Store } from "@inferno/renderer-shared-core";
import { setupExco } from "../app/core/lib/integrations/exco";

type action = (location: Location<any>) => void;

declare global {
  interface Window {
    CLIENT_NAVIGATION: boolean;
  }
}

export const useHistoryTracking = (store: Store) => {
  const history = useHistory();

  useEffect(() => {
    // These run on primary block change, which should be the same as route change, but gives you access to the block itself
    observe(
      store.block,
      "currentBlock",
      () => {
        // Rehydrate metadata
        getMetadata({ store });
        store.ads?.clear();

        /**
         * Only for Router Navigation - analytic calls
         * Initial onLoad - analytics calls have been moved to src/client/index.ts after one trust loads
         */
        if (globalThis?.window?.CLIENT_NAVIGATION) {
          store.trackPageViews();
        }
      },
      true,
    );

    // These are only run on router navigation
    // NOTE: do not add getMetadata back in here. Duplicate calls means duplicate page views in Adobe.
    const historyListeners: action[] = [
      () => {
        if (window) {
          window.CLIENT_NAVIGATION = true;
        }
      },
      () => {
        if (store.microsite && !location.pathname.includes("/featured/")) {
          store.clearMicrosite();
        }
      },
      () => store.cache.clear(),
      () => {
        if (document.body.querySelector(".template-grid")) {
          document.body.querySelector(".component-routes")!.scrollTop = 0;
        } else {
          document.body.scrollTop = document.documentElement!.scrollTop = 0;
        }
      },
      () => store.storePage(null),
      () => {
        store.request.hostname = location.hostname;
        store.request.path = location.pathname;
        store.request.protocol = location.protocol;
        store.request.hash = location.hash;
        store.device.refresh(navigator);
        const query = {};
        if (location.search.length && location.search.includes("?")) {
          location.search
            .split("?")[1]
            .split("&")
            .forEach(queryItem => {
              const [key, value] = queryItem.split("=");
              query[key] = value;
            });
        }
        store.request.query = query;
      },
    ];

    if (history) {
      if (typeof window !== "undefined") {
        historyListeners.push(() => window.scrollTo(0, 0));
        historyListeners.push(() => store.oneTrust?.setOptOutConsent("ClientNavigation"));
        historyListeners.push(() => triggerPixelPageView(store));
        historyListeners.push(() => setupExco(store));
      }
      return history.listen(location => historyListeners.forEach(listener => listener(location)));
    }
  }, [history, store]);
};
