import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { removePrefix } from "@ihr-radioedit/inferno-core";

const STYLE_COLUMNS = {
  horizontal: 1,
  "horizontal-reverse": 1,
  large: 2,
  small: 3,
  "small-scrollable": 3,
  "small-scrollable-all": 3,
  xxsmall: 5,
  xsmall: 4,
  square: 4,
  "flag-layout": 1,
};

function getColumnsFromStyle(style: string) {
  return STYLE_COLUMNS[style] || 4;
}

export const isHideOnMobileBlock = (tags: string[] = []): boolean => {
  return tags.includes("display-hints/not-for-mobile");
};

export const isHowToListenBar = (tags: string[] | null = []): boolean => {
  return !!tags?.includes("display-hints/how-to-listen-bar");
};

export const isMeetTheShowBlock = (tags: string[] | null = []): boolean => {
  return tags ? tags.includes("display-hints/meettheshow") : false;
};

export function getEyebrow(subscriptions: Webapi.PubsubDistribution[] | Webapi.Subscription[]): string {
  let topic = "";
  subscriptions.forEach((s: Webapi.PubsubDistribution | Webapi.Subscription) =>
    s.tags.forEach(t => {
      if (!topic && (t.includes("collections/") || t.includes("categories/"))) {
        topic = removePrefix(/^[\w-]+\//)(t);
      }
    }),
  );
  return topic;
}

export interface FeedDisplayHints {
  style: string;
  columns?: 1 | 2 | 3 | 4 | 5;
  showEyebrow: boolean;
  showDateStamp: boolean;
  enableLoadMore: boolean;
  isHorizontalSeeAll: boolean;
  isPodcast?: boolean;
  hints?: string[];
}

export const isPodcastFeed = (tags: string[] = []) => {
  return tags.includes("display-hints/podcasts-feed") || tags.includes("display-hints/podcasts-block");
};

export const getDisplayHints = (tags: string[] = []): FeedDisplayHints => {
  const displayHints: FeedDisplayHints = {
    style: "small",
    columns: 4,
    showDateStamp: false,
    showEyebrow: false,
    isHorizontalSeeAll: false,
    enableLoadMore: false,
    isPodcast: isPodcastFeed(tags),
    hints: tags.filter(tag => tag.includes("display-hints/")),
  };

  // Extract display hints from tags
  tags.forEach(tag => {
    if (tag.includes("display-hints")) {
      const hint = tag.replace("display-hints/", "");

      // Set known styles
      if (STYLE_COLUMNS[hint] !== undefined) {
        displayHints.style = hint;
      } else if (hint === "feed-show-eyebrow") {
        displayHints.showEyebrow = true;
      } else if (hint === "feed-load-more") {
        displayHints.enableLoadMore = true;
      } else if (hint === "feed-date-stamp") {
        displayHints.showDateStamp = true;
      } else if (hint === "flag-layout") {
        displayHints.style = "flag-layout";
      }
    }
  });

  if (tags?.includes("display-hints/small-scrollable-all") && tags.includes("display-hints/feed-see-all")) {
    displayHints.isHorizontalSeeAll = true;
  }

  // Set columns from style
  displayHints.columns = getColumnsFromStyle(displayHints.style);

  return displayHints;
};

export const getThemingCssFromTags = (tags?: string[] | null) => {
  // front-matter, no-front-matter, themed-block, square-thumbnail
  const theming = ["front-matter", "no-front-matter", "themed-block", "square-thumbnail"];
  return tags
    ?.filter(tag => tag.includes("display-hints/"))
    .map(tag => tag.replace("display-hints/", ""))
    .filter(hint => theming.includes(hint));
};

export interface PodcastDisplayHints {
  columns: 1 | 2 | 3 | 4 | 5 | 6;
  showViewMore: boolean;
  isPodcastCollection: boolean;
  isHorizontalLayout: boolean;
  isPodcastEpisodeCollection: boolean;
  hints?: string[];
}

export const getPodcastDisplayHints = (tags: string[] = []): PodcastDisplayHints => {
  const displayHints: PodcastDisplayHints = {
    columns: 4,
    showViewMore: !tags?.includes("display-hints/hide-more"),
    isPodcastCollection: false,
    isHorizontalLayout: false,
    hints: tags.filter(tag => tag.includes("display-hints/")),
    isPodcastEpisodeCollection: tags?.includes("display-hints/show-podcast-episodes-collection"),
  };

  if (tags?.includes("display-hints/podcasts-block") && tags.includes("display-hints/primary")) {
    displayHints.showViewMore = false;
    displayHints.isPodcastCollection = true;
  }

  if (tags?.includes("display-hints/podcasts-block") && tags.includes("display-hints/podcast-feed-horizontal-all")) {
    displayHints.columns = 1;
    displayHints.showViewMore = false;
    displayHints.isPodcastCollection = true;
    displayHints.isHorizontalLayout = true;
  }

  if (tags?.includes("display-hints/podcasts-block") && tags.includes("display-hints/podcast-small-thumb")) {
    displayHints.columns = 6;
    displayHints.showViewMore = true;
    displayHints.isPodcastCollection = true;
  }

  if (tags?.includes("display-hints/tile")) {
    displayHints.columns = 1;
  }

  return displayHints;
};
