import loadable from "@loadable/component";
import classnames from "classnames";
import { inject, observer } from "mobx-react";
import { Fragment } from "react";
import type { BlockFragment, SitesTemplateInfernoMicrosite } from "@ihr-radioedit/inferno-webapi";
import { Region } from "../../page-blocks/Region.component";
import { Container } from "../../ui";
import { ErrorBoundary } from "../ErrorBoundary.component";
import { MicrositeType } from "../microsite/MicrositeTypes";
import type { PageProps } from "./Page.component";

const MicrositeMenu = loadable(() => import("../microsite/MicrositeMenu.component"));
const MicrositeHeader = loadable(() => import("../microsite/MicrositeHeader.component"));

const RIGHT_RAIL = ["ad:top", "ad:sponsor", "right:dynamic", "recommendation:right", "cume:right"];
const BOTTOM = ["ad:bottom-leaderboard"];
const TOP = ["main:front-matter", "header:dynamic"];
const DEPRECATED = ["ad:top-leaderboard", "ad:middle-leaderboard", "ad:rail", "ad:mid", "ad:sponsor-byline"];

const LocalAlerts = loadable(() => import("../../chrome/LocalAlerts.component"), {
  ssr: false,
});

interface RegionMap {
  [column: string]: {
    [region: string]: BlockFragment[];
  };
}

const createRegionsFromBlocks = (blocks: BlockFragment[]) => {
  // Group blocks
  const blocksByRegion: RegionMap = blocks
    .filter(block => !DEPRECATED.includes(block.region))
    .reduce((p, c) => {
      const col = getColumn(c);
      p[col] = p[col] || {};
      p[col][c.region] = p[col][c.region] || [];
      p[col][c.region].push(c);
      return p;
    }, {});

  return blocksByRegion;
};

const getColumn = (block: BlockFragment) => {
  if (RIGHT_RAIL.includes(block.region)) {
    return "right";
  } else if (BOTTOM.includes(block.region)) {
    return "bottom";
  } else if (TOP.includes(block.region) || block.type === "onair") {
    return "top";
  } else {
    return "left";
  }
};

export const Regions = inject("store")(
  observer((props: PageProps) => {
    if (!props.store) {
      return null;
    }
    const { page, params, store, primaryBlockId, siteTheme, blockComponents } = props;
    const { site, microsite } = store;
    const regions = createRegionsFromBlocks(page.blocks);
    const microConfig = microsite ? microsite.sections : undefined;
    const microIndex = microsite ? microsite.index : undefined;

    const classes = classnames("component-page", `layout-${page.layoutId.replace(/_/g, "-")}`, {
      "two-column": !!regions.right,
      [`page-${page.name.replace(/_/g, "-")}`]: page.name,
      microsite: microIndex && microConfig,
      [site.index.slug]: true,
    });
    const showGutters = !!regions.right;

    return (
      <Fragment>
        {microIndex && microConfig ? (
          <Fragment>
            <div className="alerts">
              <ErrorBoundary>
                <LocalAlerts alert={site.sections.alert} />
              </ErrorBoundary>
            </div>

            <div className="component-microsite-header microsite themed-block">
              <Container block={false}>
                <MicrositeHeader
                  general={microConfig.general}
                  social={microConfig.social}
                  type={MicrositeType.personality}
                  view_name={page.name}
                  slug={microIndex.slug}
                  config={microConfig as SitesTemplateInfernoMicrosite}
                />
              </Container>
            </div>
            <Container block={false}>
              {microConfig?.navigation ? <MicrositeMenu navigation={microConfig.navigation} /> : null}
            </Container>
          </Fragment>
        ) : null}
        <main className={classes}>
          {!microConfig || !microIndex ? (
            <div className="alerts">
              <ErrorBoundary>
                <LocalAlerts alert={site.sections.alert} />
              </ErrorBoundary>
            </div>
          ) : null}
          {showGutters ? <div className="page-gutter gutter-left">&nbsp;</div> : null}
          {Object.keys(regions).map(region => (
            <section className={`col-${region}`} key={region}>
              {Object.keys(regions[region]).map(k => (
                <Region
                  blockComponents={blockComponents}
                  key={k}
                  name={k}
                  blocks={regions[region][k]}
                  page={page}
                  params={params}
                  primaryBlockId={primaryBlockId}
                  siteTheme={siteTheme}
                />
              ))}
            </section>
          ))}
          {showGutters ? <div className="page-gutter gutter-right">&nbsp;</div> : null}
        </main>
      </Fragment>
    );
  }),
);
