import type {
  SitesTemplateInfernoMicrositeSocial,
  SitesTemplateInfernoSiteSocial,
} from "@ihr-radioedit/inferno-webapi";
import { ILog } from "@ihr-radioedit/inferno-core";
const log = ILog.logger("social.lib");

export interface SocialNetworkData {
  network: string;
  username: string;
}

export function socialConfigAbstract(
  socialConfig?: SitesTemplateInfernoSiteSocial | SitesTemplateInfernoMicrositeSocial | null,
) {
  if (!socialConfig) {
    return [];
  }

  const networks: SocialNetworkData[] = [];
  for (const [key, value] of Object.entries(socialConfig)) {
    const [network, item] = key.split("_", 2);
    if (item === "switch" && value) {
      networks.push({
        network,
        username: socialConfig[`${network}_name`],
      });
    }
  }
  return networks;
}

export type ShareData = {
  title?: string;
  text?: string;
  url?: string;
};

export const nativeShare = (data: ShareData) => {
  log.debug("data = ", data);
  if (!navigator.share) {
    return;
  }
  try {
    navigator
      .share(data)
      .then(() => {
        log.debug("share worked?");
      })
      .catch(error => {
        log.debug("share error", error);
      });
  } catch (e) {
    log.error("native sharing failed", e.message);
  }
};
