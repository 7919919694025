import { action, makeObservable, observable } from "mobx";
import stringify from "fast-json-stable-stringify";

import { ILog, generateCatalogUrl, CatalogKindTypes, CatalogUrlProps } from "@ihr-radioedit/inferno-core";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import type { Store } from ".";
import {
  BaseStorePlayer,
  ListenLive,
  togglePlayerOverride,
  togglePlayerProps,
} from "../abstracts/store/base-player.abstract";

const log = ILog.logger("Store.Player");

/** Add new members to Base Abstract */
export class StorePlayer extends BaseStorePlayer {
  private overrideConfig: togglePlayerOverride | null = null;
  loaded = false;

  /* track active default station */
  @observable
  defaultId: string | null = null;

  /* track active catalog item */
  @observable
  overrideId: string | number | undefined | null = null;

  /* track when catalog/station is ready */
  @observable
  ready = false;

  /* track playback state */
  @observable
  playback = false;

  /* track playback progress */
  @observable
  inProgress = false;

  /* internal for ihr player bar only */
  @observable
  iframeUrl = "";

  constructor() {
    super();
    makeObservable(this);
  }

  @action
  onReady = (store: Store): void => {
    this.ready = true;
    if (this.overrideConfig) {
      this.onPause();
      this.executePlayback(store, this.overrideConfig);
      this.overrideId = this.getOverrideId();
      this.overrideConfig = null;
      this.defaultId = null;
      return;
    }
    this.defaultId = this.defaultStation(store).override.id;
    this.overrideId = null;
  };

  @action
  onError = (store: Store): void => {
    this.onPause();
    this.ready = false;
    this.overrideConfig = null;
    this.setIframeUrl(this.getDefaultIframeUrl(store));
  };

  @action
  onPlay = (): void => {
    this.playback = true;
    this.inProgress = false;
  };

  @action
  onPause = (): void => {
    this.playback = false;
    this.inProgress = false;
  };

  @action
  onProgress = (): void => {
    this.inProgress = true;
  };

  @action
  setIframeUrl = (url: string): void => {
    log.debug(url);
    this.iframeUrl = url;
  };

  /* prepare for onReady Event */
  init = (store: Store): void => {
    this.loaded = true;
    this.setIframeUrl(this.getDefaultIframeUrl(store));
  };

  togglePlayback = (store: Store, playbackProps: togglePlayerProps): void => {
    if (!this.loaded) {
      log.debug("IHR Player not initialized");
      return;
    }

    if (playbackProps.override) {
      if (!playbackProps.override.related_id) {
        delete playbackProps.override.related_id;
      }
      const overrideUrl = this.getOverrideIframeUrl(store, playbackProps.override);

      if (this.diffPlayerUrl(overrideUrl)) {
        this.refreshPlayer(overrideUrl, playbackProps);
        return;
      }
    }

    this.executePlayback(store, playbackProps);
  };

  getDefaultIframeUrl(store: Store): string {
    if (!this.checkDesign(store)) {
      return "";
    }

    const { override } = this.defaultStation(store);

    return generateCatalogUrl(
      { national_site_url: store.env.NATIONAL_SITE_URL, node_env: store.env.NODE_ENV },
      {
        ...this.getCatalogBaseConfig(store, { default: true }),
        id: override.id,
        kind: CatalogKindTypes[override.kind],
      },
    );
  }

  private getOverrideId() {
    let overrideId;
    switch (this.overrideConfig?.kind) {
      case CatalogKindTypes.podcast:
        overrideId = this.overrideConfig.related_id || this.overrideConfig.slug;
        break;
      default:
        overrideId = this.overrideConfig?.id;
        break;
    }
    return overrideId;
  }

  defaultStation(store: Store) {
    const stream: Webapi.Maybe<Pick<Webapi.CatalogPointerRef, "id" | "kind">> = {
      id: store.site.getStreamId() || "",
      kind: "station",
    };

    const override: Webapi.Maybe<Pick<Webapi.CatalogPointerRef, "id" | "kind">> =
      store.site.config.sections?.design?.ihr_embed_player_bar_stream_override || stream;

    return {
      override,
    };
  }

  private checkDesign(store: Store) {
    const design = store.site.config.sections?.design;
    if (!design || !design.ihr_embed_player_bar_switch || !design.ihr_embed_player_bar_stream_theme) {
      return false;
    }
    return true;
  }

  /* prepare for onReady Event */
  private refreshPlayer(overrideUrl: string, playbackProps: togglePlayerProps): void {
    this.overrideConfig = {
      ...playbackProps.override!,
      slug: playbackProps.slug!,
      sectionName: playbackProps.sectionName,
      batch: playbackProps.batch,
    };
    this.setIframeUrl(overrideUrl);
    this.ready = false;
  }

  private executePlayback(store: Store, playbackProps: togglePlayerProps): void {
    if (!this.ready) {
      log.error("IHR Player not ready");
      return;
    }
    this.dispatchAnalytics(store, playbackProps.sectionName);
    this.dispatchExternal();
    if (playbackProps.batch && typeof playbackProps.batch === "function") {
      playbackProps.batch();
    }
  }

  private diffPlayerUrl(incomingUrl: string): boolean {
    const iframeEl = document.querySelector("#ihr-player-bar-frame") as HTMLIFrameElement;
    const currUrl = decodeURIComponent(iframeEl.src);
    return currUrl !== incomingUrl;
  }

  private getCatalogBaseConfig(
    store: Store,
    baseProps: { togglePlayerOverride?: togglePlayerOverride | CatalogUrlProps; default?: boolean },
  ) {
    return {
      embed: true,
      proxy: true,
      query: {
        sc: "inferno",
        pname: store.microsite?.index?.slug.toUpperCase() || store.site.index.slug.toUpperCase(),
        theme: store.site.config.sections?.design?.ihr_embed_player_bar_stream_theme || "light",
        configOverride: baseProps.default
          ? store.env.ENVIRONMENT === "stage"
            ? stringify({
                api: {
                  client: `https://${store.env.AMP_HOST}`,
                  server: `https://${store.env.AMP_HOST}`,
                },
              })
            : undefined
          : undefined,
        campid: store.request.query?.campid ? store.request.query.campid : undefined,
      },
    };
  }

  private getOverrideIframeUrl(store: Store, playbackOverride: CatalogUrlProps): string {
    if (!this.checkDesign(store)) {
      return "";
    }

    return generateCatalogUrl(
      { national_site_url: store.env.NATIONAL_SITE_URL, node_env: store.env.NODE_ENV },
      {
        ...this.getCatalogBaseConfig(store, { togglePlayerOverride: playbackOverride }),
        ...playbackOverride,
      },
    );
  }

  private dispatchAnalytics(store: Store, sectionName: string): void {
    store.onAnalyticsAction.dispatch({
      sectionName,
      context: `listen_live`,
      action: "click",
      pageName: store.page.currentPage ? `${store.microsite ? "microsite_" : ""}${store.page.currentPage.name}` : "",
      referrer: window.location.href,
    });
  }

  private dispatchExternal(): void {
    this.externalAction.dispatch({ action: this.playback ? ListenLive.PAUSE : ListenLive.PLAY });
  }

  public getOverride(): togglePlayerOverride | null {
    return this.overrideConfig;
  }
}
