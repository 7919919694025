// One tag to rule them all, one tag to find them, One tag to bring them all and in the darkness bind them.
import { ILog, parseTtl, removePrefix } from "@ihr-radioedit/inferno-core";

import { ampItemTags } from "./amp-blocks";
import * as Blocks from "./blocks";
import * as Brands from "./brands";
import * as MicrositeBlocks from "./microsite-blocks";
import { pageTags } from "./page";
import { previewTags } from "./preview";
import { FORMAT_DETECTION, generateMetaTag, MICROSOFT_VALIDATE } from "./util";
import type { Store } from "../stores";
import {
  Tagger,
  TagContext,
  Tags,
  AdTags,
  AnalyticsTags,
  MetaTag,
  LinkTag,
  TitleTag,
  PageMapTag,
} from "../lib/tagging-type";
import { isPreviewRequest, reverseRoute } from "../utilities/route";
const log = ILog.logger("getMetadata");

export interface MetaStore extends Store {
  viewType?: string;
}

export interface GetMetaDataProps {
  store: MetaStore;
  reset?: boolean;
  isAmp?: boolean;
}

const noop: Tagger = (state, context: TagContext) => {
  const { block } = context;
  if (block) {
    log.debug(`No tagger for ${block.type} block`);
  }

  return state;
};

export const getMetadata = ({ store, isAmp = false }: GetMetaDataProps) => {
  const {
    request,
    site,
    block: { currentBlock: block },
    page: { currentPage: page },
    env,
    microsite,
    viewType,
  } = store;

  if (isAmp) {
    request.path = reverseRoute(site, "detail", { slug: request.params?.slug || request.query?.content }) || "";

    if (request.params && request.params?.type === "calendar") {
      request.path = reverseRoute(site, "calendar_content", { slug: request.params.slug }) || "";
    }
  }

  let tags: Tags = {
    ads: null,
    analytics: null,
    metadata: new Map(),
    surrogateKeys: new Set(store.tags.surrogateKeys),
    ttls:
      request.path === "/"
        ? {
            cdn: parseTtl("cdn-home"),
            browser: parseTtl("browser-home"),
          }
        : null,
  };

  const taggers: Tagger[] = [globalAdTags, globalAnalyticsTags, globalMetaTags, Brands.site];

  if (microsite) {
    taggers.push(Brands.microsite);
  }

  if (block) {
    const { type } = block;
    const blockTag = Blocks[type] || noop;
    taggers.push(blockTag);

    if (microsite) {
      const microBlockTag = MicrositeBlocks[type] || noop;
      taggers.push(microBlockTag);
    }

    if (isAmp) {
      taggers.push(ampItemTags);
    }
  }

  if (page) {
    taggers.push(pageTags);
  }

  if (isPreviewRequest(request.path)) {
    taggers.push(previewTags);
  }

  try {
    taggers.forEach(tagger => {
      tags = tagger(tags, { site, block, page, microsite, request, env, viewType, log });
    });
  } catch (err) {
    console.error("Error Running Taggers: ", err);
  }

  store.storeTags(tags);
};

const globalAdTags: Tagger = (state: Tags, { request, page, microsite, env, viewType }: TagContext) => {
  return {
    ...state,
    ads: {
      ...state.ads,
      microsite: microsite?.index ? microsite.index.slug : "",
      path: request.path,
      type: page?.displayName.toLowerCase() || viewType?.toLowerCase() || "",
      env: request.query?.takeover || env.ENVIRONMENT,
      topics: [],
    } as AdTags,
  };
};

const globalAnalyticsTags: Tagger = (state: Tags, { site, request, page, microsite }: TagContext) => {
  const market = site.index.market ? removePrefix(/^markets\//)(site.index.market) : "";
  const hostPrefix = market === "premiere" ? market : "local";

  return {
    ...state,
    analytics: {
      ...state.analytics,
      pageName: `${microsite ? "microsite_" : ""}${page?.name.toLowerCase() || ""}`,
      view: {
        asset: {
          id: "",
          name: "",
        },
        filter: {
          name: "",
          type: "",
        },
        authorId: "",
        pubDate: "",
        contentOrigin: "",
        contentOriginType: "",
        contentFrame: request.query?.sc === "live_profile" ? "inapp" : "page",
        stationCallLetter: "",
        stationFormat: "",
        stationMarket: market,
        stationMicrosite: "",
        tags: [],
        topics: [],
        contentId: "",
        personalityId: "",
        photoGalleryExists: "false",
        photoGalleryPageView: "false",
      },
      device: {
        host: hostPrefix + ".inferno.us",
        subHost: site.getPrimaryDomain(),
        path: request.path,
      },
      querystring: {
        sc: !!request.query?.sc ? request.query.sc : "",
        cid: !!request.query?.cid ? request.query.cid : "",
        keyid: !!request.query?.keyid ? request.query.keyid : "",
        pname: !!request.query?.pname ? request.query.pname : "",
        campid: !!request.query?.campid ? request.query.campid : "",
      },
    } as AnalyticsTags,
  };
};

const globalMetaTags: Tagger = (state: Tags, {}: TagContext) => {
  let { metadata } = state;

  if (!metadata) {
    metadata = new Map<string, MetaTag | LinkTag | TitleTag | PageMapTag>();
  }

  metadata.set(...generateMetaTag("property", "og:locale", "en_us"));
  metadata.set(...generateMetaTag("name", "msapplication-TileColor", "#fff"));
  metadata.set(...generateMetaTag("name", "msvalidate.01", MICROSOFT_VALIDATE));
  metadata.set(...generateMetaTag("name", "format-detection", FORMAT_DETECTION));

  return {
    ...state,
    metadata,
  };
};
