import { PayloadInterface, PayloadInterfaceLegacy } from "@ihr-radioedit/inferno-core";
import type { ContentPayload } from "@ihr-radioedit/inferno-webapi";
import { type Store } from "@inferno/renderer-shared-core";
import * as utf8 from "utf8";
import { isPayloadInterface } from "./guards";

export const trimText = (description: string, length: number, showOmission = true) => {
  if (!description) {
    return "";
  }
  if (description.length > length) {
    const omission = showOmission ? "..." : "";
    return description.trim().substring(0, length).split(" ").slice(0, -1).join(" ") + omission;
  }
  return description;
};

// If we have a scenario where we have two uses of this, like nav plus site leave modal (from PRN),
// then this will preserve the body-block.
const usages: { [key: string]: boolean } = {};
export const bodyScroll = (allow: boolean, key: string) => {
  if (typeof document !== "undefined") {
    const body = document.querySelector("body");
    if (allow) {
      usages[key] = false;
      if (!Object.values(usages).some(Boolean)) {
        (body as HTMLElement).classList.remove("no-scroll");
      }
    } else {
      usages[key] = true;
      (body as HTMLElement).classList.add("no-scroll");
    }
  }
};

export const getSocialShareInsertion = (payload: PayloadInterfaceLegacy | ContentPayload | PayloadInterface) => {
  const insertionBlockType = ["embed", "asset"];

  let insertSocialButtons: boolean;

  if (isPayloadInterface(payload)) {
    const { blocks } = payload.fields;
    insertSocialButtons = !!blocks.value?.length && insertionBlockType.includes(blocks.value[0].type);
  } else {
    insertSocialButtons = payload.blocks.length > 1 && insertionBlockType.includes(payload.blocks[0].type);
  }

  return { insertSocialButtons, insertSocialButtonsPosition: 0 };
};

export const base64Decode = (value: string) => {
  if (value) {
    return typeof atob !== "undefined" ? atob(utf8.encode(value)) : Buffer.from(utf8.encode(value)).toString("ascii");
  }

  return value;
};

export const orderObjectByKeys = (object: { [key: string]: any }) => {
  return Object.keys(object)
    .sort()
    .reduce((obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {} as { [key: string]: any });
};

export const checkValue = (value: any, defaultValue: any) => value || defaultValue;

export const isPage = (store: Store, page: string) => {
  return store?.page.currentPage?.name === page;
};
