import { getCoastBlockType } from ".";

import type { BlockInterface } from "@ihr-radioedit/inferno-core";
import type { FeedResultFragment } from "@ihr-radioedit/inferno-webapi";
import type { SrcSet } from "@inferno/renderer-shared-core";
import { COAST_CATEGORIES_MAP } from "@inferno/renderer-shared-core";
import type { Store } from "@inferno/renderer-shared-core";

export enum CoastFeedItemType {
  IN_THE_NEWS_HOMEPAGE = "inTheNewsHomepage",
  IN_THE_NEWS = "in-the-news",
  SOMEWHERE_IN_TIME = "somewhere-in-time",
  ART_BELL_VAULT = "art-bell-vault",
  UPCOMING_SHOWS_PREVIEW = "upcoming-shows-preview",
  UPCOMING_SHOWS = "upcoming-shows",
  CLASSIC_SHOWS = "classic-shows",
  ARTICLE = "article",
  PHOTO = "photo",
  SHOW = "show",
  SHOW_TONIGHT = "show-tonight",
  SHOW_LAST_NIGHT = "show-last-night",
  GUEST = "guest",
  CAROUSEL = "carousel",
  CALENDAR = "calendar",
}
// maps display-hints to feed item types
const CoastHintsToFeedType = {
  "coast-in-the-news-homepage": CoastFeedItemType.IN_THE_NEWS_HOMEPAGE,
  "coast-in-the-news": CoastFeedItemType.IN_THE_NEWS,
  "coast-somewhere-in-time": CoastFeedItemType.SOMEWHERE_IN_TIME,
  "coast-upcoming-shows-preview": CoastFeedItemType.UPCOMING_SHOWS_PREVIEW,
  "coast-upcoming-shows": CoastFeedItemType.UPCOMING_SHOWS,
  "coast-art-bell-vault": CoastFeedItemType.ART_BELL_VAULT,
  "coast-classic-shows": CoastFeedItemType.CLASSIC_SHOWS,
  "coast-photos": CoastFeedItemType.PHOTO,
  "coast-articles": CoastFeedItemType.ARTICLE,
  "coast-shows": CoastFeedItemType.SHOW,
  "coast-shows-last-night": CoastFeedItemType.SHOW_LAST_NIGHT,
  "coast-shows-tonight": CoastFeedItemType.SHOW_TONIGHT,
  "coast-guests": CoastFeedItemType.GUEST,
  "coast-carousel": CoastFeedItemType.CAROUSEL,
  "coast-calendar": CoastFeedItemType.CALENDAR,
};

export const getCoastFeedType = (hints: string[]) => getCoastBlockType(hints, CoastHintsToFeedType);

// TODO: add sizes attribute as well?
// TODO: may need to adjust image sizes for Photos
export const getCoastFeedImg = (image: string, type: CoastFeedItemType, opts: { [key: string]: string | number }) => {
  const { quality = 80 } = opts;
  let srcset: SrcSet[] = [];
  let imgSrc = "";
  switch (type) {
    case CoastFeedItemType.UPCOMING_SHOWS:
    case CoastFeedItemType.CAROUSEL:
    case CoastFeedItemType.GUEST:
    case CoastFeedItemType.ARTICLE:
    case CoastFeedItemType.IN_THE_NEWS:
    case CoastFeedItemType.PHOTO:
    case CoastFeedItemType.SOMEWHERE_IN_TIME:
    case CoastFeedItemType.ART_BELL_VAULT:
    case CoastFeedItemType.SHOW:
    case CoastFeedItemType.SHOW_TONIGHT:
    case CoastFeedItemType.SHOW_LAST_NIGHT:
    case CoastFeedItemType.CLASSIC_SHOWS:
      imgSrc = `${image}?ops=gravity(%22north%22),fit(330,186),quality(${quality})`;
      srcset = [
        {
          url: imgSrc,
          descriptor: "330w",
        },
        {
          url: `${image}?ops=gravity(%22north%22),fit(420,236),quality(${quality})`,
          descriptor: "420w",
        },
        {
          url: `${image}?ops=gravity(%22north%22),fit(510,287),quality(${quality})`,
          descriptor: "510w",
        },
        {
          url: `${image}?ops=gravity(%22north%22),fit(620,349),quality(${quality})`,
          descriptor: "620w",
        },
      ];
      break;
    default:
      break;
  }
  return { imgSrc, srcset };
};

export const getFeedSubtitle = (params: Record<string, string>, includeCategory = true) => {
  const selectedCategory = params.coastCategory;
  const selectedYear = params.year;

  const displayYear = selectedYear || "Most Recent";
  if (includeCategory) {
    const displayCategory =
      selectedCategory && selectedCategory in COAST_CATEGORIES_MAP
        ? COAST_CATEGORIES_MAP[selectedCategory]
        : "All Categories";

    return `${displayYear} - ${displayCategory}`;
  }
  return displayYear;
};

export interface CoastFeedShowsProps {
  content: FeedResultFragment[] | null;
  block: BlockInterface;
  type: CoastFeedItemType;
  store?: Store;
  title: string;
}

export type CoastArchiveFeedShowsProps = Pick<CoastFeedShowsProps, "content" | "block" | "store" | "title">;

export const FULL_DATE_ITEM_TYPES = new Set([
  CoastFeedItemType.SOMEWHERE_IN_TIME,
  CoastFeedItemType.ART_BELL_VAULT,
  CoastFeedItemType.CLASSIC_SHOWS,
]);
