import { AnalyticsEvent, BaseStore } from "../store/base-store.abstract";

export abstract class ManageCommon {
  protected abstract _library?: Promise<void>;
  protected abstract store: BaseStore;

  abstract loadLibrary(): Promise<void>;
  abstract trackPageView(): void;
  abstract trigger(event: AnalyticsEvent): void;
}
