// Put some common messages here
export const COAST_MESSAGES = {
  UNEXPECTED_ERROR: "An unexpected error occurred. Please try again.",
  MISSING_ACCESS_TOKEN: "We're sorry! There was an error processing your request. Please re-login and try again!",
  REQUEST_PROCESSING_ERROR: "We're sorry! There was an error processing your request. Please try again!",
  SUCCESSFUL_UPDATE: "Your account has been successfully updated.",
  SUCCESSFUL_SUBSCRIPTION_REACTIVATE: "Your subscriptions was successfully reactivated",
  SUBSCRIPTION_CANCEL_SUCCESS: "Your subscription has been successfully cancelled.",
  SUBSCRIPTION_UPDATE_ERROR: "There was an error updating your subscription plan. Please try again later.",
  SUBSCRIPTION_UPDATE_SUCCESS:
    "Your subscription has been successfully changed. " +
    "It may take a few minutes for your account to reflect the updates.",
  SUBSCRIPTION_RECIPIENT_ERROR: "There was an error processing your intended gift recipient. Please try again later.",
  INVALID_LINK:
    "The link you are trying to access is not valid. " + "Please verify the link you clicked on or request a new one.",
  EMAIL_UPDATE_CONFIRMATION_LINK:
    "We've sent a confirmation to the email address you provided. " +
    "Please check your email in order to complete the process.",
  ACCOUNT_FETCHING_ERROR: "There was an error loading your account information. Please try again later",
  FETCHING_ERROR: "There was an error loading required information. Please try again later",
  EMAIL_GEORGE_LOGIN: "You must be logged in to email George.",
  EMAIL_GEORGE_SUCCESS: "Thank You! Your request has been received.",
};

export const COAST_MOBILE_LOGO = "https://i.iheart.com/v3/re/new_assets/62802bb4-228b-4b4a-b0ab-fe5b9df6e880";

export const ABV_HEADER_IMAGE = "https://i.iheart.com/v3/re/assets.coast2coast/676c9c70-9d08-49bd-b5b3-0ac5496d6b34";
export const SIT_HEADER_IMAGE = "https://i.iheart.com/v3/re/assets.coast2coast/62155cbe06307be33c75039b";

export const COAST_CATEGORY_PLACEHOLDER = "all-categories";

export const COAST_CATEGORIES = {
  regular: [
    { name: "alternative-history-conspiracies", displayName: "Alternative History & Conspiracies" },
    { name: "alternative-medicine-health", displayName: "Alternative Medicine & Health" },
    { name: "ancient-world", displayName: "Ancient World" },
    { name: "artwork-comics-multimedia", displayName: "Artwork, Comics & Multimedia" },
    { name: "coast-to-coast-am", displayName: "Coast To Coast AM" },
    { name: "creatures-myths-legends", displayName: "Creatures, Myths & Legends" },
    { name: "current-events-economy", displayName: "Current Events/Economy" },
    { name: "extraterrestrial", displayName: "Extraterrestrial" },
    { name: "miscellaneous", displayName: "Miscellaneous" },
    { name: "mysteries-anomalies", displayName: "Mysteries & Anomalies" },
    { name: "nature-environment-earth-changes", displayName: "Nature, Environment & Earth Changes" },
    { name: "pop-culture", displayName: "Pop Culture" },
    { name: "prophecy-predictions", displayName: "Prophecy & Predictions" },
    { name: "psychology-mind", displayName: "Psychology & Mind" },
    { name: "science-space-technology", displayName: "Science, Space & Technology" },
    { name: "spirituality-occult", displayName: "Spirituality & Occult" },
    { name: "supernatural-paranormal", displayName: "Supernatural & Paranormal" },
    { name: "top-secret", displayName: "Top Secret" },
    { name: "true-crime", displayName: "True Crime" },
    { name: "ufos-aerial-phenomena", displayName: "UFOs & Aerial Phenomena" },
    { name: "weird-bizarre", displayName: "Weird & Bizarre" },
  ],
  hosts: [
    { name: "host-george-noory", displayName: "George Noory" },
    { name: "host-george-knapp", displayName: "George Knapp" },
    { name: "host-art-bell", displayName: "Art Bell" },
    { name: "host-ian-punnett", displayName: "Ian Punnett" },
    { name: "host-richard-syrett", displayName: "Richard Syrett" },
    { name: "host-connie-willis", displayName: "Connie Willis" },
    { name: "host-lisa-garr", displayName: "Lisa Garr" },
  ],
};

export const COAST_CATEGORIES_MAP = {
  "alternative-history-conspiracies": "Alternative History & Conspiracies",
  "alternative-medicine-health": "Alternative Medicine & Health",
  "ancient-world": "Ancient World",
  "artwork-comics-multimedia": "Artwork, Comics & Multimedia",
  "coast-to-coast-am": "Coast To Coast AM",
  "creatures-myths-legends": "Creatures, Myths & Legends",
  "current-events-economy": "Current Events/Economy",
  extraterrestrial: "Extraterrestrial",
  miscellaneous: "Miscellaneous",
  "mysteries-anomalies": "Mysteries & Anomalies",
  "nature-environment-earth-changes": "Nature, Environment & Earth Changes",
  "pop-culture": "Pop Culture",
  "prophecy-predictions": "Prophecy & Predictions",
  "psychology-mind": "Psychology & Mind",
  "science-space-technology": "Science, Space & Technology",
  "spirituality-occult": "Spirituality & Occult",
  "supernatural-paranormal": "Supernatural & Paranormal",
  "top-secret": "Top Secret",
  "true-crime": "True Crime",
  "ufos-aerial-phenomena": "UFOs & Aerial Phenomena",
  "weird-bizarre": "Weird & Bizarre",
  "host-george-noory": "George Noory",
  "host-george-knapp": "George Knapp",
  "host-art-bell": "Art Bell",
  "host-ian-punnett": "Ian Punnett",
  "host-richard-syrett": "Richard Syrett",
  "host-connie-willis": "Connie Willis",
  "host-lisa-garr": "Lisa Garr",
};

export const US_STATES_AND_TERRITORIES = [
  { label: "Alabama (AL)", value: "AL" },
  { label: "Alaska (AK)", value: "AK" },
  { label: "American Samoa (AS)", value: "AS" },
  { label: "Arizona (AZ)", value: "AZ" },
  { label: "Arkansas (AR)", value: "AR" },
  { label: "Armed Forces Africa (AE)", value: "AE" },
  { label: "Armed Forces Americas (AA)", value: "AA" },
  { label: "Armed Forces Canada (AE)", value: "AE" },
  { label: "Armed Forces Europe (AE)", value: "AE" },
  { label: "Armed Forces Middle East (AE)", value: "AE" },
  { label: "Armed Forces Pacific (AP)", value: "AP" },
  { label: "California (CA)", value: "CA" },
  { label: "Colorado (CO)", value: "CO" },
  { label: "Connecticut (CT)", value: "CT" },
  { label: "Delaware (DE)", value: "DE" },
  { label: "District of Columbia (DC)", value: "DC" },
  { label: "Florida (FL)", value: "FL" },
  { label: "Georgia (GA)", value: "GA" },
  { label: "Guam (GU)", value: "GU" },
  { label: "Hawaii (HI)", value: "HI" },
  { label: "Idaho (ID)", value: "ID" },
  { label: "Illinois (IL)", value: "IL" },
  { label: "Indiana (IN)", value: "IN" },
  { label: "Iowa (IA)", value: "IA" },
  { label: "Kansas (KS)", value: "KS" },
  { label: "Kentucky (KY)", value: "KY" },
  { label: "Louisiana (LA)", value: "LA" },
  { label: "Maine (ME)", value: "ME" },
  { label: "Maryland (MD)", value: "MD" },
  { label: "Massachusetts (MA)", value: "MA" },
  { label: "Michigan (MI)", value: "MI" },
  { label: "Minnesota (MN)", value: "MN" },
  { label: "Mississippi (MS)", value: "MS" },
  { label: "Missouri (MO)", value: "MO" },
  { label: "Montana (MT)", value: "MT" },
  { label: "Nebraska (NE)", value: "NE" },
  { label: "Nevada (NV)", value: "NV" },
  { label: "New Hampshire (NH)", value: "NH" },
  { label: "New Jersey (NJ)", value: "NJ" },
  { label: "New Mexico (NM)", value: "NM" },
  { label: "New York (NY)", value: "NY" },
  { label: "North Carolina (NC)", value: "NC" },
  { label: "North Dakota (ND)", value: "ND" },
  { label: "Northern Mariana Islands (MP)", value: "MP" },
  { label: "Ohio (OH)", value: "OH" },
  { label: "Oklahoma (OK)", value: "OK" },
  { label: "Oregon (OR)", value: "OR" },
  { label: "Pennsylvania (PA)", value: "PA" },
  { label: "Puerto Rico (PR)", value: "PR" },
  { label: "Rhode Island (RI)", value: "RI" },
  { label: "South Carolina (SC)", value: "SC" },
  { label: "South Dakota (SD)", value: "SD" },
  { label: "Tennessee (TN)", value: "TN" },
  { label: "Texas (TX)", value: "TX" },
  { label: "Utah (UT)", value: "UT" },
  { label: "Vermont (VT)", value: "VT" },
  { label: "Virgin Islands (VI)", value: "VI" },
  { label: "Virginia (VA)", value: "VA" },
  { label: "Washington (WA)", value: "WA" },
  { label: "West Virginia (WV)", value: "WV" },
  { label: "Wisconsin (WI)", value: "WI" },
  { label: "Wyoming (WY)", value: "WY" },
];

export const CANADA_PROVINCES_AND_TERRITORIES = [
  { label: "Alberta (AB)", value: "AB" },
  { label: "British Columbia (BC", value: "BC" },
  { label: "Manitoba (MB)", value: "MB" },
  { label: "New Brunswick (NB)", value: "NB" },
  { label: "Newfoundland and Lab", value: "NL" },
  { label: "Nova Scotia (NS)", value: "NS" },
  { label: "Ontario (ON)", value: "ON" },
  { label: "Prince Edward Island", value: "PE" },
  { label: "Quebec (QC)", value: "QC" },
  { label: "Saskatchewan (SK)", value: "SK" },
  { label: "Northwest Territorie", value: "NT" },
  { label: "Nunavut (NU)", value: "NU" },
  { label: "Yukon (YT)", value: "YT" },
];

export const RESPONSIVE_IMAGE = {
  responsiveImageInitialWidth: 288,
  responsiveImageInitialHeight: 162,
};
